* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Raleway',
    sans-serif;

}

body {
  overflow: hidden;
}

*::selection {
  background-color: #FCB9B2;
}


.noise {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: -3;
}

.back {
  display: block;
  width: 38px;
  height: 28px;
  margin: 0 auto;
  border: 2px solid #ebebeb83;
  position: absolute;
  left: 30px;
  bottom: 40px;
}

.back:hover {
  border: 2px solid #FCB9B2;
}


.welcome {
  width: auto;
  position: fixed;
  z-index: 3;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FCB9B2;
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
  font-size: 1.3rem;
  letter-spacing: 3px;
  text-transform: lowercase;
}

.first-heading {
  width: auto;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 52%;
  transform: translate(-50%, -50%);
  height: auto;
  text-align: center;
  color: #ebebeb;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
}

.about {
  width: 300px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  text-align: center;
  color: #ebebeb;
}

.about p {
  text-align: center;
  word-break: normal;
}

.about h2 {
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
}



.creative {
  letter-spacing: 0.7rem;
  padding-bottom: 0.7rem;
  font-size: 1.5rem;
  display: inline-block;
}


.creative-red {
  color: #FCB9B2;
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
}

.creative-white {
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
  color: #EBEBEB;
}

.about h2 {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: 900;
  color: #ebebeb;
}

.about p {
  font-size: 0.6rem;
}

.about>* {
  padding: 0.5rem;
  line-height: 0.8rem;
  letter-spacing: 2px;
}



.li {
  list-style-type: none;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 3px;
  color: rgb(233, 233, 233);
}

.li:hover {
  color: #FCB9B2;
  /* text-decoration: line-through; */
  transition: all 0.2s ease-in-out;
}


.top-left-about {
  position: absolute;
  left: 30px;
  top: 30px;
}

.top-right-work {
  position: absolute;
  top: 30px;
  right: 30px;
}

.bottom-left-contact {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hello {
  padding: 1rem;
  color: #ebebeb;
  text-align: center;
  width: 300px;
  margin: 0 auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.hello p {
  padding: 1rem;
}

.hello h4 {
  padding-bottom: 1rem;
}

.creative-hello {
  font-size: 1.6rem;
  color: #FCB9B2;
  font-family: 'Abril Fatface',
    cursive;
  font-family: 'Amatic SC',
    cursive;
}

.worky-work {
  color: #ebebeb;
  text-align: center;
  width: 300px;
  margin: 0 auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
}

.worky-work h4 {
  font-size: 1.3rem;
  line-height: 2rem;
}


/*carousel*/

.carousel-slide {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  transform: translate(-50%, -50%);
}

.carousel .carousel-status {
  display: none;
}

.carousel.carousel-slider .control-arrow {
  padding: 0.5rem 1rem;
}


.thumbs-wrapper {
  display: none;
}

.control-dots {
  display: none;
}

.carousel.carousel-slider .control-arrow:hover {
  background: none;
}


.carousel .slide .legend {
  background: transparent;
  font-size: 0.5rem;
  bottom: 10px;
}


.carousel .slide {
  background: none;
}

.link-projects {
  display: block;
  height: 100%;
}

.emailme {
  display: block;
  text-decoration: none;
  color: #ebebeb;
  padding-top: 1rem;
}

.emailme:hover {
  color: #FCB9B2;
}


@media only screen and (min-width: 600px) {
  * {
    cursor: none;
  }

  .welcome {
    top: 40%;
    font-size: 3rem;

  }

  .first-heading {

    font-size: 1.8rem;

  }

  h1 {
    font-size: 2.6rem;
  }

  .li {
    font-size: 1.3rem;
  }

  .creative {
    font-size: 2rem;
  }

  .about {
    width: 600px;
  }

  .about h2 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .about p {
    font-size: 1.2rem;
  }

  .about>* {
    padding: 1rem;
    line-height: 1.3rem;
    letter-spacing: 2px;
  }

  .hello {
    width: 500px;
  }

  .creative-hello {
    font-size: 1.8rem;
  }

  /*carousel*/

  .carousel-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    transform: translate(-50%, -50%);
  }

  .thumbs-wrapper {
    display: none;
  }

  .control-dots {
    display: none;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }

  .link-projects {
    display: block;
    height: 100%;
  }

  #cursor-dot,
  #cursor-dot-outline {
    z-index: 999;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 0.35s ease-in-out, transform 0.35s ease-in-out;

  }

  #cursor-dot {
    width: 20px;
    height: 20px;
    border: 1px solid #FCB9B2;
  }

  #cursor-dot-outline {
    width: 20px;
    height: 20px;
    background-color: rgba(252, 185, 178, 0.5);
  }

}

@media only screen and (min-width: 800px) {
  h1 {
    font-size: 3rem;
  }

  .li {
    font-size: 1.6rem;
  }

  .about {
    width: 800px;
  }

  .creative {
    font-size: 2.8rem;
  }

  .about>* {
    line-height: 1.8rem;
  }

  .about p {
    font-size: 1.4rem;
  }

  .about h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .hello {
    font-size: 1.6rem;

  }

  .creative-hello {
    font-size: 2rem;
  }

  .worky-work h4 {
    font-size: 1.6rem;
  }

  /*carousel*/

  .carousel-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 800px;
    transform: translate(-50%, -50%);
  }

  .thumbs-wrapper {
    display: none;
  }

  .control-dots {
    display: none;
  }

  .carousel.carousel-slider .control-arrow:hover {
    background: none;
  }

  .showcase-detail {
    position: absolute;
    top: 50%;
  }



}